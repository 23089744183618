.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color:rgb(63,81,181);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}
.footer {
  width: 100vw;
  background-color:  rgb(63,81,181);
  height: 11vh;
  color: rgb(53, 54, 54);
  text-align: center;
  line-height: 11vh;
}