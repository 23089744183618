.link{
  text-decoration-line: none;
  color: #580cfa;
  font-size: 20px;
}
.description{
  color: black; 
  font-size: 20px;
  white-space: pre-line;
  padding-left: 100px;
}
.header{
  
  padding-left: 40px;
}
.image{
    border: 1px solid #ddd;
    border-radius: 4px;
    padding-left: 400px;
    width: 500px;
    cursor: zoom-in;
    cursor:zoom-out;
}

  